import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ERC20RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Erc20AutoClaimEstimation } from '@stichting-allianceblock-foundation/abridge-sdk/dist/modules/ERC20Router/types/ERC20Autoclaim';
import { Stepper } from '@stichting-allianceblock-foundation/components';
import { NETWORK_CONFIG } from 'configs';
import { TOKEN_FEE_DECIMALS, WALLET_TYPE } from 'configs/constants';
import { ethers } from 'ethers';
import { useGlobalContext } from 'hooks/useGlobalContext';
import useWallet from 'hooks/useWallet';
import {
  formatCurrency,
  fromWei,
  getBlockExplorerNameByChainId,
  getDefaultAddress,
  hexlifyAddress,
  isAddress,
  toBN,
  toWei,
  TxStatus,
} from 'utils';
import { currencySymbols, getCoingeckooIds, getTokenPrice } from 'utils/coingecko';
import { signErc20EgressPermit, tokenSupportsErc20Permit } from 'utils/sdk';
import { getSolBalance } from 'utils/solana';

import { StepBridgeButtons, StepDetails, StepReview } from './Steps';

import './StepperBridge.scss';

interface StepperBridgeProps {
  activeStep: number;
  wasTransactionCleared: boolean;
  serviceFeeTokenIndex: number;
  tokenSelectedBalance: string;
  sliderPercentage: number;
  selectedToken: TokenDetails;
  bridgeSteps: BridgeSteps;
  updateActiveStep: (step: 0 | 1) => void;
  updateBridgeSteps: React.Dispatch<React.SetStateAction<BridgeSteps>>;
  updateServiceFeeTokenIndex: (index: number) => void;
  updateWasTransferSuccessful: (wasSuccessful: boolean) => void;
  updateWasTransactionClear: (wasCleared: boolean) => void;
  updateTokenSelectedBalance: (balance: string) => void;
  updateSliderPercentage: (percentage: number) => void;
  updateSelectedToken: (token: TokenDetails) => void;
  clearBridgeTransaction: () => void;
}

// constants
const INSTALLED = 'Installed';

const StepperBridge = ({
  activeStep,
  wasTransactionCleared,
  serviceFeeTokenIndex,
  tokenSelectedBalance,
  sliderPercentage,
  selectedToken,
  bridgeSteps,
  updateActiveStep,
  updateBridgeSteps,
  updateServiceFeeTokenIndex,
  updateWasTransferSuccessful,
  updateWasTransactionClear,
  updateSliderPercentage,
  updateSelectedToken,
  updateTokenSelectedBalance,
  clearBridgeTransaction,
}: StepperBridgeProps) => {
  const {
    sdk,
    config,
    currentNetwork,
    bridgeTransaction,
    serviceFeeOptions,
    setBridgeTransaction,
  } = useGlobalContext();
  const { account, library, provider, type } = useWallet();

  const [tokenList, setTokenList] = useState<TokenDetails[]>([]);
  const [txFeeEstimation, setTxFeeEstimation] = useState<string>('0.0');
  const [txFeeEstimationCurrency, setTxFeeEstimationCurrency] = useState<string>('($ 0.00)');
  const [isNonDefaultFeeTokenSelected, setIsNonDefaultFeeTokenSelected] = useState<boolean>(false);
  const [notEnoughBalanceForServiceFee, setNotEnoughBalanceForServiceFee] =
    useState<boolean>(false);

  const [nativeTokenBalance, setNativeTokenBalance] = useState<ethers.BigNumber>(toBN('0'));
  const [isBridgeTokenApproved, setIsBridgeTokenApproved] = useState<boolean>(false);
  const [isServiceTokenApproved, setIsServiceTokenApproved] = useState<boolean>(false);
  const [isTokenNonApprovable, setIsTokenNonApprovable] = useState<boolean>(false);
  const [isTokenEWTB, setIsTokenEWTB] = useState<boolean>(false);
  const [isAutoClaimActive, setIsAutoClaimActive] = useState(false);
  const [currentAllowance, setCurrentAllowance] = useState<ethers.BigNumber | undefined>(undefined);
  const [autoClaimEstimation, setAutoClaimEstimation] = useState<AutoClaimEstimation>({
    fee: '0.0',
    feeInCurrency: '($ 0.00)',
    expiry: 0,
    signature: '',
  });
  const [autoClaimHasFunds, setAutoClaimHasFunds] = useState<boolean | null>(null);
  const [loadingProviderFee, setLoadingProviderFee] = useState(false);
  const [providerFee, setProviderFee] = useState(0);

  const maxAttempts: number = 10;

  const updateAllowance = useCallback(async () => {
    if (!sdk || !account || !bridgeTransaction?.token?.amount) return;
    const currentAllowance = await sdk
      .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
      .allowance(bridgeTransaction?.token?.address, account);
    setCurrentAllowance(currentAllowance.allowance);
  }, [sdk, account, bridgeTransaction?.token?.amount, bridgeTransaction?.token?.address]);

  const onSelectedFeeTokenChange = useMemo(() => {
    return (option: ServiceFeeToken | null) => {
      if (option === null) {
        updateServiceFeeTokenIndex(-1);
        return;
      }
      for (let index = 0; index < serviceFeeOptions.length; index++) {
        if (serviceFeeOptions[index]?.address === option?.address) {
          updateServiceFeeTokenIndex(index);
          break;
        }
      }

      setNotEnoughBalanceForServiceFee(false);
      setIsNonDefaultFeeTokenSelected(
        currentNetwork.nativeCurrency.symbol.toLowerCase() !== option?.symbol?.toLowerCase(),
      );
      setBridgeTransaction(prevBridgeTransaction => {
        return {
          ...prevBridgeTransaction,
          feeToken: {
            details: {
              name: option.name,
              symbol: option.symbol,
              decimals: option.decimals,
              icon: option.icon,
            },
            amount: option.amount,
            address: option.address,
          },
        };
      });
    };
  }, [
    currentNetwork.nativeCurrency.symbol,
    serviceFeeOptions,
    setBridgeTransaction,
    updateServiceFeeTokenIndex,
  ]);

  useEffect(() => {
    onSelectedFeeTokenChange(serviceFeeOptions.length > 0 ? serviceFeeOptions[0] : null);
  }, [onSelectedFeeTokenChange, serviceFeeOptions]);

  const approveServiceFeeToken = async () => {
    if (sdk) {
      try {
        updateBridgeSteps({
          ...bridgeSteps,
          approveServiceFeeToken: false,
          processing: true,
        });

        const payableCall = await sdk
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .approveMaxInt(bridgeTransaction?.feeToken?.address);

        const contractTransaction = await payableCall.sendTransaction();

        updateBridgeSteps({
          ...bridgeSteps,
          approveServiceFeeToken: false,
          processing: true,
          blockExplorer: {
            name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
            hash: contractTransaction.hash,
            blockExplorerUrl: currentNetwork?.blockExplorerUrl,
            queryParams: currentNetwork?.blockExplorerQueryParams,
          },
        });

        const receiptTxWait = await contractTransaction.wait();

        if (receiptTxWait.status === TxStatus.READY) {
          setIsServiceTokenApproved(true);
          updateBridgeSteps({
            ...bridgeSteps,
            approveServiceFeeToken: false,
            approveBridgeToken:
              bridgeTransaction?.feeToken?.address === bridgeTransaction?.token?.address ||
              isBridgeTransactionTokenNativeToken,
            transfer:
              bridgeTransaction?.feeToken?.address === bridgeTransaction?.token?.address ||
              isBridgeTransactionTokenNativeToken,
            processing: false,
          });
        } else {
          updateBridgeSteps({
            ...bridgeSteps,
            approveServiceFeeToken: true,
            processing: false,
          });
        }
      } catch (err) {
        console.error(err);
        updateBridgeSteps({
          ...bridgeSteps,
          approveServiceFeeToken: true,
          processing: false,
        });
      }
    }
  };

  const approveBridgeToken = async () => {
    if (sdk) {
      try {
        updateBridgeSteps({
          ...bridgeSteps,
          approveBridgeToken: false,
          processing: true,
        });

        const tokenWeiAmount = toWei(
          bridgeTransaction?.token?.amount,
          bridgeTransaction?.token?.details?.decimals,
        );

        const shouldSetAllowanceTo0 =
          currentAllowance?.gt(0) && currentAllowance?.lt(tokenWeiAmount); //If we need to reset the allowance, we will need to set it to 0 first

        const payableCall = await sdk
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .approve(bridgeTransaction?.token?.address, shouldSetAllowanceTo0 ? 0 : tokenWeiAmount);

        const contractTransactionResult = await payableCall.sendTransaction();

        updateBridgeSteps({
          ...bridgeSteps,
          approveBridgeToken: false,
          processing: true,
          blockExplorer: {
            name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
            hash: contractTransactionResult.hash,
            blockExplorerUrl: currentNetwork?.blockExplorerUrl,
            queryParams: currentNetwork?.blockExplorerQueryParams,
          },
        });

        const receiptTx = await contractTransactionResult.wait();

        await updateAllowance();

        if (receiptTx.status === TxStatus.READY && !shouldSetAllowanceTo0) {
          setIsBridgeTokenApproved(true);
          updateBridgeSteps({
            ...bridgeSteps,
            approveBridgeToken: false,
            transfer: true,
            processing: false,
          });
        } else {
          updateBridgeSteps({
            ...bridgeSteps,
            approveBridgeToken: true,
            processing: false,
          });
        }
      } catch (err) {
        console.error(err);
        updateBridgeSteps({
          ...bridgeSteps,
          approveBridgeToken: true,
          processing: false,
        });
      }
    }
  };

  const sendBridgeToken = async () => {
    if (
      sdk &&
      isAddress(bridgeTransaction.recipient, bridgeTransaction.network.target.walletType)
    ) {
      try {
        updateBridgeSteps({
          ...bridgeSteps,
          transfer: false,
          processing: true,
        });

        const recipient = hexlifyAddress(
          bridgeTransaction.recipient,
          bridgeTransaction.network.target.walletType,
        );
        let nonApprovableOptions = {};
        if (isTokenNonApprovable) {
          const deadline = +new Date() + 60 * 60;
          const signature = await signErc20EgressPermit(
            sdk,
            bridgeTransaction?.token?.address,
            account as string,
            deadline,
            currentNetwork?.chainId,
            toWei(bridgeTransaction?.token?.amount, bridgeTransaction?.token?.details?.decimals),
          );
          nonApprovableOptions = {
            deadline,
            v: signature.v,
            r: signature.r,
            s: signature.s,
          };
        }

        const payableCall = await sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).sendErc20({
          targetChainId: bridgeTransaction?.network?.target?.chainTargetId,
          tokenAddress: bridgeTransaction?.token?.address,
          amount: toWei(
            bridgeTransaction?.token?.amount,
            bridgeTransaction?.token?.details?.decimals,
          ),
          receiverAddress: recipient,
          feeTokenAddress: isNonDefaultFeeTokenSelected
            ? bridgeTransaction.feeToken.address
            : ethers.constants.AddressZero,
          deliveryFeeEstimate: !isAutoClaimActive ? undefined : toWei(autoClaimEstimation.fee),
          deliveryFeeExpiry: !isAutoClaimActive ? undefined : autoClaimEstimation.expiry,
          deliveryFeeSignature: !isAutoClaimActive ? undefined : autoClaimEstimation.signature,
          ...nonApprovableOptions,
        });

        const contractTransactionResult = await payableCall.sendTransaction();

        updateBridgeSteps({
          ...bridgeSteps,
          transfer: false,
          processing: true,
          blockExplorer: {
            name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
            hash: contractTransactionResult.hash,
            blockExplorerUrl: currentNetwork?.blockExplorerUrl,
            queryParams: currentNetwork?.blockExplorerQueryParams,
          },
        });

        const receiptTx = await contractTransactionResult.wait();

        if (receiptTx.status === TxStatus.READY) {
          updateWasTransferSuccessful(true);
          updateBridgeSteps({
            ...bridgeSteps,
            transfer: false,
            processing: false,
          });
        } else {
          updateBridgeSteps({
            ...bridgeSteps,
            transfer: true,
            processing: false,
          });
        }
      } catch (err) {
        console.error(err);
        updateBridgeSteps({
          ...bridgeSteps,
          transfer: true,
          processing: false,
        });
      }
    }
  };
  const isBridgeTransactionTokenNativeToken = useMemo(() => {
    return bridgeTransaction?.token?.address === getDefaultAddress(type);
  }, [bridgeTransaction?.token?.address, type]);

  useEffect(() => {
    updateActiveStep(0);
    const loadAutoClaimEstimation = async () => {
      if (
        sdk &&
        bridgeTransaction?.network?.source?.chainTargetId &&
        bridgeTransaction?.network?.target?.chainTargetId &&
        bridgeTransaction?.token?.address &&
        bridgeTransaction?.token?.amount &&
        account &&
        bridgeTransaction?.recipient &&
        isAutoClaimActive
      ) {
        const erc20AutoClaimEstimation: Erc20AutoClaimEstimation = await sdk
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .estimateErc20AutoClaim(
            bridgeTransaction?.network?.source?.chainTargetId,
            bridgeTransaction?.network?.target?.chainTargetId,
            bridgeTransaction?.token?.address,
            bridgeTransaction?.token?.amount,
            account,
            bridgeTransaction?.recipient,
          );
        setAutoClaimHasFunds(erc20AutoClaimEstimation.deliveryAgentHasFunds);
        const deliveryFeeData: DeliveryFeeData = erc20AutoClaimEstimation.data;
        const fee = fromWei(deliveryFeeData.fee, currentNetwork?.nativeCurrency?.decimals);

        const coingeckoId = getCoingeckooIds(
          currentNetwork?.nativeCurrency?.symbol?.toLowerCase(),
        )?.id;

        let formattedPrice: string = `(${currencySymbols[config.serviceFeeCurrency]} N/A)`;
        try {
          const price = await getTokenPrice(coingeckoId, config.serviceFeeCurrency);

          formattedPrice = formatCurrency(
            Number(fee) * price,
            TOKEN_FEE_DECIMALS,
            currencySymbols[config.serviceFeeCurrency],
          );
        } catch (err) {
          console.error('loadAutoClaimEstimation', err);
        }

        setAutoClaimEstimation(prevAutoClaimEstimation => {
          return {
            ...prevAutoClaimEstimation,
            fee,
            expiry: deliveryFeeData.expiry,
            signature: deliveryFeeData.signature,
            feeInCurrency: formattedPrice,
          };
        });
      }
    };

    loadAutoClaimEstimation();
  }, [
    account,
    bridgeTransaction?.network?.source?.chainTargetId,
    bridgeTransaction?.network?.target?.chainTargetId,
    bridgeTransaction?.recipient,
    bridgeTransaction?.token?.address,
    bridgeTransaction?.token?.amount,
    config.serviceFeeCurrency,
    currentNetwork?.nativeCurrency?.decimals,
    currentNetwork?.nativeCurrency?.symbol,
    isAutoClaimActive,
    sdk,
    updateActiveStep,
  ]);

  useEffect(() => {
    const resetAutoClaimEstimationOnDeactivate = () => {
      if (!isAutoClaimActive) {
        setAutoClaimEstimation({
          fee: '0.0',
          feeInCurrency: '($ 0.00)',
          expiry: 0,
          signature: '',
        });
      }
    };

    resetAutoClaimEstimationOnDeactivate();
  }, [isAutoClaimActive]);

  useEffect(() => {
    const checkForNonApprovableToken = async () => {
      if (sdk && currentNetwork?.chainId) {
        try {
          const nativeToken = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getNativeTokenByWrappedAddress(bridgeTransaction?.token?.address);
          if (nativeToken.contractAddress !== '0x' && account) {
            setIsTokenNonApprovable(
              await tokenSupportsErc20Permit(sdk, bridgeTransaction?.token?.address, account),
            );
          }
        } catch (error) {
          setIsTokenNonApprovable(false);
        }
      } else {
        setIsTokenNonApprovable(false);
      }
    };
    checkForNonApprovableToken();
  }, [account, bridgeTransaction?.token?.address, currentNetwork?.chainId, sdk]);

  useEffect(() => {
    const checkServiceFeeTokenApproval = async () => {
      if (sdk && bridgeTransaction?.feeToken?.address && account) {
        try {
          if (bridgeTransaction?.feeToken?.address !== ethers.constants.AddressZero) {
            const approved = await sdk
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .isTokenApproved(
                bridgeTransaction?.feeToken?.address,
                account,
                toWei(
                  bridgeTransaction?.feeToken?.amount,
                  bridgeTransaction?.feeToken?.details?.decimals,
                ),
              );
            setIsServiceTokenApproved(approved);
          } else {
            setIsServiceTokenApproved(true);
          }
        } catch (err) {
          console.error('checkServiceFeeTokenApproval', err);
          setIsServiceTokenApproved(false);
        }
      }
    };

    checkServiceFeeTokenApproval();

    return () => {
      setIsServiceTokenApproved(false);
    };
  }, [
    account,
    bridgeTransaction?.feeToken?.address,
    bridgeTransaction?.feeToken?.amount,
    bridgeTransaction?.feeToken?.details?.decimals,
    sdk,
    isNonDefaultFeeTokenSelected,
  ]);

  useEffect(() => {
    const checkBridgeTokenApproval = async () => {
      if (
        sdk &&
        account &&
        bridgeTransaction?.token?.address &&
        !['0', '0.0']?.includes(bridgeTransaction?.token?.amount)
      ) {
        try {
          let approved: boolean = false;

          if (!isBridgeTransactionTokenNativeToken) {
            approved = await sdk
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .isTokenApproved(
                bridgeTransaction?.token?.address,
                account,
                toWei(
                  bridgeTransaction?.token?.amount,
                  bridgeTransaction?.token?.details?.decimals,
                ),
              );
          }

          setIsBridgeTokenApproved(isBridgeTransactionTokenNativeToken ? true : approved);
        } catch (err) {
          console.error('checkBridgeTokenApproval', err);
          setIsBridgeTokenApproved(false);
        }
      }
    };
    checkBridgeTokenApproval();
  }, [
    account,
    bridgeTransaction?.token?.address,
    bridgeTransaction?.token?.amount,
    bridgeTransaction?.token?.details?.decimals,
    isBridgeTransactionTokenNativeToken,
    isNonDefaultFeeTokenSelected,
    sdk,
  ]);

  useEffect(() => {
    const resetTransactionCleared = () => {
      if (
        tokenSelectedBalance !== '0' ||
        sliderPercentage > 0 ||
        bridgeTransaction?.network?.target?.chainId ||
        bridgeTransaction?.token?.address ||
        bridgeTransaction?.recipient !== account ||
        bridgeTransaction?.feeToken?.address !== serviceFeeOptions[0]?.address ||
        selectedToken?.address
      ) {
        setTxFeeEstimation('0.0');
        setTxFeeEstimationCurrency('($ 0.00)');
        setAutoClaimEstimation({
          fee: '0.0',
          feeInCurrency: '($ 0.00)',
          expiry: 0,
          signature: '',
        });
        updateWasTransactionClear(false);
      }
    };
    resetTransactionCleared();
  }, [
    account,
    bridgeTransaction?.feeToken?.address,
    bridgeTransaction?.network?.target?.chainId,
    bridgeTransaction?.recipient,
    bridgeTransaction?.token?.address,
    serviceFeeOptions,
    selectedToken?.address,
    sliderPercentage,
    tokenSelectedBalance,
    updateWasTransactionClear,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadTransactionEstimation = async () => {
      if (
        sdk &&
        tokenSelectedBalance !== '0' &&
        bridgeTransaction?.network?.target?.chainId &&
        bridgeTransaction?.token?.address &&
        bridgeTransaction?.recipient &&
        bridgeTransaction?.feeToken?.address &&
        selectedToken?.address &&
        isAddress(bridgeTransaction.recipient, bridgeTransaction.network.target.walletType)
      ) {
        const recipient = hexlifyAddress(
          bridgeTransaction.recipient,
          bridgeTransaction.network.target.walletType,
        );
        const gasPrice = (await library?.getGasPrice()) ?? toBN('1');

        let approved: boolean = false;
        let gasEstimation: ethers.BigNumber = toBN('0');
        let feeEstimation: ethers.BigNumber = toBN('0');

        if (!isBridgeTransactionTokenNativeToken && account) {
          approved = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .isTokenApproved(
              bridgeTransaction?.token?.address,
              account,
              toWei(bridgeTransaction?.token?.amount, bridgeTransaction?.token?.details?.decimals),
            );
        }

        if (approved || isBridgeTransactionTokenNativeToken) {
          const payableCall = await sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).sendErc20({
            targetChainId: bridgeTransaction.network.target.chainTargetId,
            tokenAddress: bridgeTransaction.token.address,
            amount: toWei(bridgeTransaction.token.amount, bridgeTransaction.token.details.decimals),
            receiverAddress: recipient,
            feeTokenAddress: isNonDefaultFeeTokenSelected
              ? bridgeTransaction.feeToken.address
              : ethers.constants.AddressZero,
          });
          try {
            gasEstimation = await payableCall.estimateGas();
          } catch (error: unknown) {
            console.warn('Transaction gas estimation failed: ', (error as Error)?.message);
          }
          feeEstimation = gasEstimation.mul(gasPrice);
        } else {
          const tokenWeiAmount = toWei(
            bridgeTransaction?.token?.amount,
            bridgeTransaction?.token?.details?.decimals,
          );
          await updateAllowance();

          const shouldSetAllowanceTo0 =
            currentAllowance?.gt(0) && currentAllowance?.lt(tokenWeiAmount); //If we need to reset the allowance, we will need to set it to 0 first
          try {
            gasEstimation = await sdk
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .approve(
                bridgeTransaction?.token?.address,
                shouldSetAllowanceTo0 ? 0 : tokenWeiAmount,
              )
              .estimateGas();
          } catch (error: unknown) {
            console.warn('Transaction gas estimation failed: ', (error as Error)?.message);
          }
          feeEstimation = gasEstimation.mul(gasPrice);
        }
        setTxFeeEstimation(fromWei(feeEstimation, currentNetwork?.nativeCurrency?.decimals));
      }
    };
    try {
      loadTransactionEstimation();
    } catch (error) {
      attemptCount++;
      if (attemptCount < maxAttempts) {
        loadTransactionEstimation();
      }
    }
  }, [
    account,
    bridgeTransaction.feeToken.address,
    bridgeTransaction.network.target?.chainId,
    bridgeTransaction.network.target.chainTargetId,
    bridgeTransaction.network.target.walletType,
    bridgeTransaction.recipient,
    bridgeTransaction.token.address,
    bridgeTransaction.token.amount,
    bridgeTransaction.token.details.decimals,
    currentAllowance,
    currentNetwork?.nativeCurrency?.decimals,
    isBridgeTransactionTokenNativeToken,
    isNonDefaultFeeTokenSelected,
    library,
    provider,
    sdk,
    selectedToken?.address,
    sliderPercentage,
    tokenSelectedBalance,
    type,
    updateAllowance,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadTxFeeEstimationCurrency = async () => {
      if (!['0', '0.0']?.includes(txFeeEstimation)) {
        const coingeckoId = getCoingeckooIds(
          currentNetwork?.nativeCurrency?.symbol?.toLowerCase(),
        )?.id;

        let formattedPrice: string = `(${currencySymbols[config.serviceFeeCurrency]} N/A)`;
        try {
          const price = await getTokenPrice(coingeckoId, config.serviceFeeCurrency);

          formattedPrice = formatCurrency(
            Number(txFeeEstimation) * price,
            TOKEN_FEE_DECIMALS,
            currencySymbols[config.serviceFeeCurrency],
          );
        } catch (err) {
          console.error('loadTxFeeEstimationCurrency', err);
        }
        setTxFeeEstimationCurrency(formattedPrice);
      }
    };
    try {
      loadTxFeeEstimationCurrency();
    } catch (error) {
      attemptCount++;
      console.error(`loadTxFeeEstimationCurrency attempt:${attemptCount}`, error);
      if (attemptCount < maxAttempts) {
        loadTxFeeEstimationCurrency();
      }
    }
  }, [config.serviceFeeCurrency, currentNetwork?.nativeCurrency?.symbol, txFeeEstimation]);

  useEffect(() => {
    const clearStepperAfterNetworkChange = () => {
      updateActiveStep(0);
      updateBridgeSteps(prevBridgeSteps => {
        return {
          ...prevBridgeSteps,
          next: true,
        };
      });
      clearBridgeTransaction();
      setIsTokenEWTB(false);
    };

    clearStepperAfterNetworkChange();
  }, [clearBridgeTransaction, updateActiveStep, updateBridgeSteps]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadBalanceIfTokenIsSelected = async () => {
      if (
        sdk &&
        account &&
        // If library is available and provider is not, then we are connected to a Metamask wallet and we need to use the library
        // If provider is available, then we are connected to a Phantom wallet and we need to use the sdk
        (library || provider) &&
        selectedToken?.address
      ) {
        let tokenBalance: ethers.BigNumber = toBN('0');
        if (selectedToken.address === getDefaultAddress(type)) {
          tokenBalance = nativeTokenBalance;
        } else {
          // Tokens
          tokenBalance = (
            await sdk
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .balanceOfMulticall([selectedToken.address], account)
          )[0];
        }

        if (tokenBalance?.gte(0)) {
          updateTokenSelectedBalance(fromWei(tokenBalance, selectedToken.decimals));
        }
      }
    };
    try {
      loadBalanceIfTokenIsSelected();
    } catch (error) {
      attemptCount++;
      console.error(`loadBalanceIfTokenIsSelected attempt:${attemptCount}}`, error);
      if (attemptCount < maxAttempts) {
        loadBalanceIfTokenIsSelected();
      }
    }
  }, [
    account,
    library,
    nativeTokenBalance,
    provider,
    sdk,
    selectedToken.address,
    selectedToken.decimals,
    type,
    updateTokenSelectedBalance,
  ]);

  useEffect(() => {
    if (serviceFeeOptions?.length > 0) {
      const serviceFeeToken = serviceFeeOptions[0];
      setBridgeTransaction(prevBridgeTransaction => {
        return {
          ...prevBridgeTransaction,
          feeToken: {
            details: {
              name: serviceFeeToken.name,
              symbol: serviceFeeToken.symbol,
              decimals: serviceFeeToken.decimals,
              icon: serviceFeeToken.icon,
            },
            amount: serviceFeeToken.amount,
            address: serviceFeeToken.address,
          },
        };
      });
    }
  }, [serviceFeeOptions, setBridgeTransaction]);

  useEffect(() => {
    let attemptCount: number = 0;
    const getNativeTokenBalance = async () => {
      // NativeToken
      let tokenBalance = toBN('0');
      if (currentNetwork.walletType === WALLET_TYPE.PHANTOM) {
        // Solana
        // FIXME: check wallet.connected with Solana
        // @ts-ignore
        if (provider && provider.wallet.wallet.readyState === INSTALLED && provider.publicKey) {
          tokenBalance = await getSolBalance(provider);
        }
      } else {
        // EVM
        if (library && account) {
          tokenBalance = await library.getBalance(account);
        }
      }
      setNativeTokenBalance(tokenBalance);
    };

    try {
      getNativeTokenBalance();
    } catch (error) {
      attemptCount++;
      console.error(`getNativeTokenBalance attempt:${attemptCount}`, error);
      if (attemptCount < maxAttempts) {
        getNativeTokenBalance();
      }
    }
  }, [account, currentNetwork.walletType, library, provider]);

  useEffect(() => {
    let attemptCount: number = 0;
    const checkServiceFeeTokenBalance = async () => {
      if (
        sdk &&
        (library || provider) &&
        account &&
        bridgeTransaction?.feeToken?.address &&
        bridgeTransaction?.token?.address &&
        bridgeTransaction?.network?.target?.chainTargetId &&
        bridgeTransaction?.recipient &&
        isAddress(bridgeTransaction.recipient, bridgeTransaction.network.target.walletType)
      ) {
        let bridgeTransactionTokenAmount = toBN('0');
        if (isBridgeTransactionTokenNativeToken) {
          bridgeTransactionTokenAmount = toWei(
            bridgeTransaction.token.amount,
            bridgeTransaction.token.details.decimals,
          );
        }

        if (
          bridgeTransaction?.feeToken?.address === getDefaultAddress(type) ||
          isBridgeTransactionTokenNativeToken
        ) {
          const amount = toWei(
            bridgeTransaction?.token?.amount,
            bridgeTransaction?.token?.details?.decimals,
          );
          if (!amount.gt(0)) {
            return;
          }
          let providerFee = toBN('0');
          try {
            providerFee = await sdk.dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME).getProviderFee({
              targetChainId: bridgeTransaction.network.target.chainTargetId,
              tokenAddress: bridgeTransaction.token.address,
              amount: amount,
              receiverAddress: hexlifyAddress(
                bridgeTransaction.recipient,
                bridgeTransaction.network.target.walletType,
              ),
            });
          } catch (error) {
            console.error(`checkServiceFeeTokenBalance`);
          }
          setNotEnoughBalanceForServiceFee(
            nativeTokenBalance
              ?.sub(bridgeTransactionTokenAmount)
              ?.sub(providerFee)
              .lt(
                toWei(
                  bridgeTransaction.feeToken.amount,
                  bridgeTransaction.feeToken.details.decimals,
                ),
              ),
          );
        } else if (bridgeTransaction?.feeToken?.address) {
          const feeTokenBalanceBN = (
            await sdk
              .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
              .balanceOfMulticall([bridgeTransaction.feeToken.address], account)
          )[0];
          if (feeTokenBalanceBN) {
            setNotEnoughBalanceForServiceFee(
              feeTokenBalanceBN
                ?.sub(bridgeTransactionTokenAmount)
                .lt(
                  toWei(
                    bridgeTransaction.feeToken.amount,
                    bridgeTransaction.feeToken.details.decimals,
                  ),
                ),
            );
          }
        }
      }
    };

    try {
      checkServiceFeeTokenBalance();
    } catch (error) {
      attemptCount++;
      console.error(`checkServiceFeeTokenBalance attempt:${attemptCount}`, error);
      if (attemptCount < maxAttempts) {
        checkServiceFeeTokenBalance();
      }
    }

    return () => {
      setNotEnoughBalanceForServiceFee(false);
    };
  }, [
    sdk,
    account,
    nativeTokenBalance,
    library,
    bridgeTransaction.feeToken.address,
    bridgeTransaction.feeToken.amount,
    bridgeTransaction.token.amount,
    provider,
    bridgeTransaction.feeToken,
    bridgeTransaction.token.details.decimals,
    isBridgeTransactionTokenNativeToken,
    bridgeTransaction.network.target.chainTargetId,
    bridgeTransaction.network.target.walletType,
    bridgeTransaction.token.address,
    bridgeTransaction.recipient,
    type,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const checkEWTB = async () => {
      if (sdk && selectedToken?.address && NETWORK_CONFIG['ewc']?.EWTB) {
        const EWTBAddresses = Object.values(NETWORK_CONFIG['ewc'].EWTB);
        setIsTokenEWTB(EWTBAddresses?.includes(selectedToken?.address?.toLowerCase()));
      }
    };
    try {
      checkEWTB();
    } catch (error) {
      attemptCount++;
      console.error(`loadNativeTokenChain attempt:${attemptCount}`, error);
      if (attemptCount < maxAttempts) {
        checkEWTB();
      }
    }
  }, [bridgeTransaction?.token?.address, sdk, selectedToken?.address]);

  return (
    <div className="bridge-stepper my-4">
      <Stepper>
        <StepDetails
          activeStep={activeStep}
          wasTransactionCleared={wasTransactionCleared}
          tokenSelectedBalance={tokenSelectedBalance}
          tokenList={tokenList}
          selectedToken={selectedToken}
          currentAllowance={currentAllowance}
          isTokenNonApprovable={isTokenNonApprovable}
          tokenSelectedAmountInWei={toWei(bridgeTransaction.token.amount, selectedToken?.decimals)}
          sliderPercentage={sliderPercentage}
          isServiceTokenApproved={isServiceTokenApproved}
          isBridgeTokenApproved={isBridgeTokenApproved}
          isAutoClaimActive={isAutoClaimActive}
          serviceFeeTokenIndex={serviceFeeTokenIndex}
          txFeeEstimation={txFeeEstimation}
          txFeeEstimationCurrency={txFeeEstimationCurrency}
          autoClaimEstimation={autoClaimEstimation}
          notEnoughBalanceForServiceFee={notEnoughBalanceForServiceFee}
          isBridgeTransactionTokenNativeToken={isBridgeTransactionTokenNativeToken}
          isTokenEWTB={isTokenEWTB}
          updateSliderPercentage={updateSliderPercentage}
          updateSelectedToken={updateSelectedToken}
          updateTokenSelectedBalance={updateTokenSelectedBalance}
          updateTokenList={setTokenList}
          updateIsAutoClaimActive={setIsAutoClaimActive}
          onSelectedFeeTokenChange={onSelectedFeeTokenChange as (option: Option) => void}
          autoClaimHasFunds={autoClaimHasFunds}
          loadingProviderFee={loadingProviderFee}
          setLoadingProviderFee={setLoadingProviderFee}
          providerFee={providerFee}
          setProviderFee={setProviderFee}
        />
        {activeStep === 1 ? (
          <StepReview
            activeStep={activeStep}
            isServiceTokenApproved={isServiceTokenApproved}
            isBridgeTokenApproved={isBridgeTokenApproved}
            isAutoClaimActive={isAutoClaimActive}
            txFeeEstimation={txFeeEstimation}
            txFeeEstimationCurrency={txFeeEstimationCurrency}
            autoClaimEstimation={autoClaimEstimation}
            providerFee={providerFee}
            loadingProviderFee={loadingProviderFee}
          />
        ) : (
          <></>
        )}
        <StepBridgeButtons
          notEnoughBalanceForServiceFee={notEnoughBalanceForServiceFee}
          isTokenEWTB={isTokenEWTB}
          isServiceTokenApproved={isServiceTokenApproved}
          isBridgeTokenApproved={isBridgeTokenApproved}
          isAutoClaimActive={isAutoClaimActive}
          isBridgeTransactionTokenNativeToken={isBridgeTransactionTokenNativeToken}
          autoClaimEstimation={autoClaimEstimation}
          bridgeSteps={bridgeSteps}
          updateBridgeSteps={updateBridgeSteps}
          updateActiveStep={updateActiveStep}
          approveServiceFeeToken={approveServiceFeeToken}
          approveBridgeToken={approveBridgeToken}
          sendBridgeToken={sendBridgeToken}
          clearBridgeTransaction={clearBridgeTransaction}
          autoClaimHasFunds={autoClaimHasFunds}
          loadingProviderFee={loadingProviderFee}
        />
      </Stepper>
    </div>
  );
};

export default StepperBridge;
